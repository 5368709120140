import React, {useContext} from 'react'
import {MyContext} from '../contexts/MyContext'

// Importing the Login & Register Componet
import Login from '../components/Login'
import Register from '../components/Register'

import Header from './Vistas/Plantilla/Header';
import Menu from './Vistas/Plantilla/Menu';




function Home() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;
    
    if(isAuth)
    {
    return (
        <div>
            
            <Header></Header>
            <Menu></Menu>
            
            
           
            
        </div>
    )
}

else if(showLogin){
    return <Login/>;
}
else{
    return <Register/>;
}

}

export default Home