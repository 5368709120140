import React, {useState,useContext} from 'react';

import {Link} from 'react-router-dom';

import '../../../App.css';
import {MyContext} from '../../../contexts/MyContext'




function Menu(props) {
  const {rootState,logoutUser} = useContext(MyContext);

  
  const [dropdown, setDropdown]=useState(false);
  const {isAuth,theUser,showLogin} = rootState;

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }
    
  return (
    <nav className="navbar  main-sidebar sidebar-light-primary elevation-4 " >
  
  
   <center>
  <a href="#" className="brand-link">
      <img src="dist/img/plantilla/logo.png" alt="HOZTCREA" width="85px" />
      <span className="brand-text font-weight-light"></span>
    </a>   </center>  

   
    <div className=" scrollb ">
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img src="dist/img/plantilla/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image"  width="170px" height="170px" />
        </div>
        <div className="info">
          <a href="#" className="d-block">{theUser.name}</a>
          
         
            <button className="btn btn-primary" onClick={logoutUser} > Salir <i className="fas fa-sign-out-alt"></i></button>
            
            
            
        </div>
      </div>

      <nav className="mt-2">
    
   
      <ul className="nav navbar-nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      
      {  theUser.tipo_usuario =="admin"
          ?
        <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/noticias.png" width="35px" /></i>
          <p>
          &nbsp; Clientes
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Clientes" className="nav-link">
            <i> <img src="dist/img/iconos/noticias.png" width="35px" /></i>
            <p>
               &nbsp;Clientes
             <b className="caret" />
           </p>
            </Link>
        </li>
       
       
      
   
          </ul>
        </li>
     : ""
     } 


{  theUser.tipo_usuario =="usuario"
          ?
        <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/datos.png" width="35px" /></i>
          <p>
          &nbsp; Productos
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Usuarios" className="nav-link">
            <i> <img src="dist/img/iconos/web.png" width="35px" /></i>
            <p>
               &nbsp;Accesos
             <b className="caret" />
           </p>
            </Link>
        </li>
       
       
      
   
          </ul>
        </li>
     : ""
     } 

      </ul>
      

     

      <ul className="nav navbar-nav nav-pills nav-sidebar flex-column">
      
        
      </ul>
    
    </nav>
  </div>
 
</nav>
        
    )

    
  }

  


export default Menu
