import React from 'react';
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import history from './components/history';

import MyContextProvider from './contexts/MyContext';
import Home from './components/Home';

import Clientes from './components/Vistas/Clientes/Clientes';
import Usuarios from './components/Vistas/Usuarios/Usuarios';


function App() {
  return (
    <MyContextProvider>
      <Router>
       <Home/>

       <Switch>
       <Route path={'/Clientes'} component = {Clientes}></Route>
       <Route path={'/Usuarios'} component = {Usuarios}></Route>
           
           <Route history={history}></Route>
           <Route component={Clientes}/>  

       </Switch>

      </Router>
    </MyContextProvider>
  );
}

export default App;
