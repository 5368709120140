import React, {useContext} from 'react'

import {MyContext} from '../../../contexts/MyContext'

function Usuarios() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">

 

        
          <div className="col-md-12">

          <div className="callout callout-info">

          <div class="col-md-3 col-sm-6 col-12">


</div>

<div class="card card-primary">
<div class="card-header">
<h3 class="card-title">PRODUCTOS</h3>
</div>


<form>
<div class="card-body">
<div class="form-group">
<label for="exampleInputEmail1">HOSTING DEDICADO</label>
<input type="text" class="form-control" id="exampleInputEmail1" placeholder="Cloud Gold Plus" readOnly />
</div>



<div class="form-group">
<label for="exampleInputEmail1">Dominio</label>
<input type="text" class="form-control" id="exampleInputEmail1" placeholder="fajasdecolombiana.com - código EPP: e76j)%#1 " readOnly />
</div>

<div class="form-group">
<label for="exampleInputEmail1">Configuración</label>
<input type="text" class="form-control" id="exampleInputEmail1" placeholder="20 GB SSD - 12 Email - Certificado SSL - Sentencias dedicadas" readOnly />
</div>

<div class="form-group">
<label for="exampleInputEmail1">Acceso SSH</label>
<input type="text" class="form-control" id="exampleInputEmail1" placeholder="server: FAJA - clave: JIUJPOLOIYTS"  readOnly />
</div>

</div>


<br/>


<div class="card card-warning">
<div class="card-header">
<h3 class="card-title ">RENOVACIÓN</h3>
<div class="card-tools">
<ul class="pagination pagination-sm float-right">
{/*<li class="page-item"><a class="page-link" href="#">«</a></li>
<li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">»</a></li>*/}
</ul>
</div>
</div>

<div class="card-body p-0">
<table class="table">
<thead>
{<tr>
<th style={{width: "10px"}}>#</th>
<th>Producto</th>
<th>Fecha de pago</th>
<th>Valor Año</th>
<th style={{width: "40px"}}>Valor x2 años promo</th>
  </tr> }
</thead>
<tbody>
<tr>
<td>1.</td>
<td>Hosting Cloud hosting plus - fajasdecolombiana.com</td>
<td>06-05-2022 </td>
<td>$290.000 COP</td>
<td><span class="badge bg-warning">390.000 COP</span></td>
</tr>
{/*
<tr>
<td>2.</td>
<td>Clientes</td>
<td>(de 1 a 2 horas) </td>
<td>$290.000 COP</td>
<td><span class="badge bg-warning">390.000 COP</span></td>
</tr>
<tr>
<td>3.</td>
<td>Inventarios</td>
<td>
  (de 2 a 8 horas)
</td>
<td>No se proceso</td>
<td><span class="badge bg-danger">0%</span></td>
</tr> 
*/}
</tbody>
</table>
</div>

</div>

</form>
</div>
             


          
            </div>

            
       
          </div>

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Usuarios
