import React,{useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';


function Clientes() {
  

  const baseUrlAd="https://www.portal.hoztcrea.com/apiHoztcrea/clientes/"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id_modulos:'',
    nombres:'',
    celular:'',
    productos:''
  });

  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

  const peticionPost = async() =>{
    var f = new FormData();
    f.append("nombres", frameworkSeleccionado.nombres);
    f.append("celular", frameworkSeleccionado.celular);
    f.append("productos", frameworkSeleccionado.productos);
    f.append("estado", frameworkSeleccionado.estado);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }



  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE");
    await axios.post(baseUrlAd,f, {params: {id_modulos: frameworkSeleccionado.id_modulos}})
    .then(response=>{
     setData(data.filter(framework=>framework.id_modulos!==frameworkSeleccionado.id_modulos));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEliminar():
    abrirCerrarModalEliminar()
  } 

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 1000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id_modulos",
      
    },
    {
      title:'NOMBRES',
      field:'nombres'
    },

    {
        title:'CELULAR',
        field:'celular'
      },

      {
        title:'PRODUCTOS',
        field:'productos'
      },
      {
        title:'ESTADO',
        field:'estado'
      },

   
    
    
  ];


    return (

        <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h3 className="m-0"><button className="btn btn-secondary" onClick={()=>abrirCerrarModalInsertar()}><img src="dist/img/iconos/noticias.png" width="45px" /> CREAR CLIENTE</button></h3>
          </div>
          <div className="col-sm-6">
            
          </div>
        </div>
      </div>
    </div>

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
          <div className="col-md-12">

         
            <div>
              
            <MaterialTable

      
     
columns={columnas}
data={data}
title="BASE DE DATOS"
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[
    
  {
   icon : 'delete',
   tooltip: 'Eliminar modulos',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }
]}
options={{
  actionsColumnIndex: -1
}}
options={{
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
 },

 headerStyle: { backgroundColor: "rgb(175 174 173)",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white" }
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>

<Modal isOpen={modalInsertar}>
 <ModalHeader><img src="dist/img/iconos/noticias.png" width="50px" /> CLIENTE</ModalHeader>
 <ModalBody>
   <div className="form-group">
     <label>Nombre del cliente:</label>
     <br/>
     
     <input type="text" className="form-control"  name="nombres" onChange={handleChange} />
     <br/>

     <label>Celular:</label>
     <br/>
     
     <input type="text" className="form-control"  name="celular" onChange={handleChange} />
     <br/>

     <label>Productos:</label>
     <br/>
     
     <textarea type="text" className="form-control"  name="productos" onChange={handleChange} />
     <br/>

      

   <select name="estado" className="form-control"  onChange={handleChange}>
               <option value='no' selected>Estado</option>
                <option value="Activo">Activo</option>
                <option value="No activo">No activo</option>
             </select>     
     <br />

     
     
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que deseas eliminar el modulo {frameworkSeleccionado && frameworkSeleccionado.modulos2}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>

            </div>
       
          </div>

         
        </div>
      

        

      </div>
    </section>

    
    
  </div>
    )
}

export default Clientes
